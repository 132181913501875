import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SecondBriefIntroImageMobile = () => {
  const data = useStaticQuery(query)

  return (
    <Img
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt="Slickwood Image"
    />
  )
}

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "brief-intro/teeth.png" }) {
      childImageSharp {
        fluid(maxWidth: 157) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default SecondBriefIntroImageMobile
